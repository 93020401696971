import request from "@/api/request";

export default {
  getRefillsReport: (query: string) =>
    request.get(`/reports/refills?${query}`, {
      responseType: "blob",
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
    }),
  getActivationsReport: (query: string) =>
    request.get(`/reports/activations?${query}`, {
      responseType: "blob",
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
    }),
  getRefundsReport: (query: string) =>
    request.get(`/reports/refunds?${query}`, {
      responseType: "blob",
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
    }),
  getExpiringSubsReport: (query: string) =>
    request.get(`/reports/expiring-subscriptions?${query}`, {
      responseType: "blob",
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
    }),
  getExpiredSubsReport: (query: string) =>
    request.get(`/reports/expired-subscriptions?${query}`, {
      responseType: "blob",
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
    }),
  getUserScansReport: (payload: any) =>
    request.post("reports/user-scans", payload, {
      responseType: "blob",
      timeout: 5 * 60 * 1000,
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
    }),
  getUsersBalanceInfo: (queryParams: any) =>
    request.get("reports/users-used-total-balance", { params: queryParams }),
};
