
import { defineComponent, ref, watchEffect } from "vue";
import { DatePicker } from "v-calendar";
import "v-calendar/dist/style.css";

export default defineComponent({
  name: "CustomDatePicker",
  components: {
    DatePicker,
  },

  props: {
    clearable: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const dates = ref(null);
    const currentDate = ref(new Date());
    // const date = new Date();
    // const minDate = date.setMonth(date.getMonth() - 1);

    const filterDate = (date: Date) => {
      if (date) {
        return new Intl.DateTimeFormat("kz-KZ", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }).format(date);
      }
    };

    const clearDate = () => {
      dates.value = null;
      emit("date-picked", dates.value);
    };

    watchEffect(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (dates.value?.start) {
        emit("date-picked", dates.value);
        // emitPickedDateAndStopWatch();
      }
    });

    // const emitPickedDateAndStopWatch = () => {
    //   emit("date-picked", dates.value);
    //   watchDate();
    // };

    return {
      dates,
      currentDate,
      filterDate,
      clearDate,
    };
  },
});
